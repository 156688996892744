<template> 
<div>
    <div v-show="!isSpiner">
      <b-card title="Personal Information" class="personal_information_wrapper">
          <b-row class="studentinformation_tabs_wrapper">
              <b-col md="2" class="Profile">   
                   <span v-show="editable!==false">
                     <img width="160px" height="145px" v-if='currentUser.profilePic !== ""' class="profile_img" :title="currentUser.profilePicName" :alt="currentUser.profilePicName" :src="currentUser.profilePic"><br>
                     <img v-if='currentUser.profilePic == ""' width="160px" height="145px" class="profile_img" title="studentProfile" alt="profileName" src="@/assets/images/avatars/defaultUser.jpg"><br>
                    <b-form-file
                        id="imagePicker"                  
                        accept="image/*"
                        ref='userProfile'
                        class="profile_img"
                        v-model="userProfile"  
                        @change="checkFile"                                   
                    >                   
                    </b-form-file>         
                   </span> 
                   <span v-show="editable==false">
                      <img class="profile_img" v-if='currentUser.profilePic !== ""' :title="currentUser.profilePicName" :alt="currentUser.profilePicName" :src="currentUser.profilePic"> 
                      <img class="profile_img"  v-if='currentUser.profilePic == ""' title="profilePicName" alt="currentUserprofilePicName" src="@/assets/images/avatars/defaultUser.jpg"> 
                   </span>            
              </b-col>
              <b-col md="10">
                <validation-observer ref="FormValidator">  
                    <form ref="editForm" >
                        <b-row class="my-1">
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group label="User ID" label-for="userId">     
                                    <span :hidden='editable==false'>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="userId"
                                            rules="required"
                                        >
                                        <b-form-input
                                            id="userId"
                                            v-model="currentUser.userId"                                    
                                            type="text"  
                                            :disabled='editable'
                                            name='userId'                          
                                            placeholder="Enter Text"
                                            :state="errors.length > 0 ? false:null"
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </span>
                                    <span v-show="editable==false" class="output_span">{{currentUser.userId}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                                <b-form-group class="mb-0" label="Name" label-for="Name">    
                                <span :hidden='editable==false'>                              
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Name"
                                            rules="required"
                                        >
                                        <b-form-input
                                            id="name"
                                            v-model="currentUser.name"                                    
                                            type="text"  
                                            :disabled='isLoading'
                                            name='Name'                          
                                            placeholder="Enter Text"
                                            :state="errors.length > 0 ? false:null"
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                                    
                                </span> 
                                <span v-show="editable==false" class="output_span">
                                    {{currentUser.name ? currentUser.name : ''}}
                                </span>                      
                            </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                                <b-form-group class="mb-0" label="Email" label-for="email">     
                                    <span :hidden='editable==false'>                              
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Email"
                                            rules="required"
                                        >
                                        <b-form-input
                                            id="email"
                                            v-model="currentUser.email"                                    
                                            type="text"  
                                            :disabled='editable'
                                            name='email'                          
                                            placeholder="Enter Text"
                                            :state="errors.length > 0 ? false:null"
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                                    
                                    </span>                                
                                    <span v-show='editable==false' class="output_span">
                                        {{currentUser.email}}
                                    </span>                                
                            </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group class="mb-0" label="Mobile No." label-for="mobileNo">                        
                                 <span :hidden='editable==false'>                              
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Mobile Number"
                                            rules="required"
                                        >
                                        <b-form-input
                                            id="mobileNo"
                                            v-model="currentUser.mobile"                                    
                                            type="text"  
                                            :disabled='isLoading'
                                            name='mobileNo'                          
                                            placeholder="Enter Text"
                                            :state="errors.length > 0 ? false:null"
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                                    
                                    </span> 
                                <span v-show='editable==false' class="output_span">
                                    {{`${currentUser.countryPhoneCode ? currentUser.countryPhoneCode.dialCode : '+91'} ` + ( (currentUser.countryPhoneCode ? formatPhoneNumber(currentUser.mobile,currentUser.countryPhoneCode.mask) : formatPhoneNumber(currentUser.mobile,'99999 99999')))}}
                                </span>             
                            </b-form-group>
                            </b-col>
                        <!-- </b-row>     
                        <b-row class="my-1"> -->
                            <b-col  lg="3" md="4" sm="4">
                                <b-form-group label="Date Of Birth" label-for="dob">     
                                    <span :hidden='editable==false'>                              
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Date of Birth"
                                            rules="required"
                                        >
                                        <b-form-input
                                            id="dob"
                                            v-model="currentUser.editDob"
                                            type="date"
                                            placeholder="YYYY-MM-DD"
                                            autocomplete="off"
                                            show-decade-nav
                                            name='dob'
                                            oncopy="return false"
                                            onpaste="return false"
                                            class="mb-1 input-date"
                                            :max="getMaxDate()"
                                            :disabled='isLoading'
                                            :state="errors.length > 0 ? false:null"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                                    
                                    </span> 
                                    <span v-show="editable==false" class="output_span">{{currentUser.dob}}</span>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                                <b-form-group class="mb-0" label="Gender" label-for="gender">    
                                 <span :hidden='editable==false'>                              
                                        <validation-provider
                                            #default="{ errors }"
                                            name="gender"
                                            rules="required"
                                        >
                                        <div style="display: flex;justify-content: space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                                
                                        <b-form-radio
                                            v-model="currentUser.gender"
                                            plain
                                            name="gender"
                                            value="Male"
                                        >
                                            Male
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="currentUser.gender"
                                            plain
                                            name="gender"
                                            value="Female"
                                        >
                                            Female
                                        </b-form-radio>
                                        <b-form-radio
                                            v-model="currentUser.gender"
                                            plain
                                            name="gender"
                                            value="Other"
                                        >
                                            Other
                                        </b-form-radio>

                                        </div>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                           
                                </span> 
                                <span v-show="editable==false" class="output_span">
                                    {{currentUser.gender}}
                                </span>                      
                            </b-form-group>
                            </b-col>
                            <b-col  lg="3" md="4" sm="4" class="mb-1 mb-md-0">                                   
                                <b-form-group class="mb-0" label="Communication Address" label-for="communicationAdd">                               
                                <span :hidden='editable==false' class="addEdit">                              
                                <!-- Address Line 1 -->                                     
                                        <b-form-group label="Address Line 1" style="padding:10px">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="address line 1"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model="currentUser.add1"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Enter Text"                                                
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>                                    
                                        <b-form-group label="Address Line 2" style="padding:10px">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="address line 2"
                                            rules=""
                                        >
                                            <b-form-input
                                                v-model="currentUser.add2"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Enter Text"                                               
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>                            
                                </span> 
                                <span v-show='editable==false' class="output_span">   
                                    {{currentUser.add1}}<br>
                                    {{currentUser.add2}}                                    
                                </span>
                                </b-form-group>
                            </b-col>
                        <!-- </b-row>  
                        <b-row> -->
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group class="" label="Representative Mobile No." label-for="representMobileNo">                        
                                 <span :hidden='editable==false'> 
                                        <b-form-input
                                            id="representMobileNo"
                                            v-model="currentUser.representative.phoneNo"                                    
                                            type="text"  
                                            :disabled='editable'
                                            name='representMobileNo'                          
                                            placeholder="Enter Text"                                          
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />                                                                          
                                    </span> 
                                <span  v-show='editable==false' class="output_span">
                                    <span v-if="currentUser.representativeRelationArray && currentUser.representativeRelationArray.length">
                                        <span v-for="(i,index) in currentUser.representativeRelationArray" :key="index">
                                            <span>{{ `${i.phoneNo} (${i.name})` }}</span><br/>
                                        </span>
                                    </span>
                                    <span v-else>--</span>
                                </span>             
                            </b-form-group>
                            </b-col>
                             <b-col lg="3" md="4" sm="4">
                                <b-form-group label="Document Module." label-for="docModule">                        
                                 <span :hidden='editable==false'> 
                                        <b-form-input
                                            id="docModule"
                                            v-model="currentUser.docModuleName"                                    
                                            type="text"  
                                            :disabled='editable'
                                            name='docModule'                          
                                            placeholder="Enter Text"                                          
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />                                                                          
                                    </span> 
                                <span  v-if='editable==false && currentUser.documentModule' class="output_span">
                                    <span v-for="(data,index) in currentUser.documentModule" :key="index">
                                        {{data.title}}{{index!=currentUser.documentModule.length-1?', ':'' }}
                                    </span>
                                </span>             
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group label="Status" label-for="status">                        
                                 <!-- <span :hidden='editable==false'> 
                                        <b-form-input
                                            id="docModule"
                                            v-model="currentUser.docModuleName"                                    
                                            type="text"  
                                            :disabled='editable'
                                            name='docModule'                          
                                            placeholder="Enter Text"                                          
                                            oncopy="return false" 
                                            onpaste="return false" 
                                            autocomplete='off'
                                        />                                                                          
                                    </span>  -->
                                <span  v-show='editable==false'>
                                   <b-badge :variant="'light-'+currentUser.userStatus.variant"> {{currentUser.userStatus.name}}</b-badge>
                                </span>             
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group label="Intake" v-if="currentUser">
                                    {{currentUser.intake||'--'}}
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="4" sm="4">
                                <b-form-group label="Year" v-if="currentUser">
                                    {{currentUser.year||'--'}}
                                </b-form-group>
                            </b-col>
                        </b-row>             
                    </form>
                </validation-observer>
              </b-col>
          </b-row>
      </b-card>
      <b-card v-show="editable==false" title="Education Details" class="card_body_bt0">
            <validation-observer ref="educationFormValidator">  
                <form ref="editForm" >
                    <b-row class="mt-1" v-for="(edu,index) in currentUser.education" :key="index">
                        <b-col lg="3" md="4" sm="4" v-if="edu.education && edu.edId == undefined">
                            <b-form-group label="Education" label-for="cllgName">     
                                <!-- <span :hidden='editable==false'>
                                    <validation-provider
                                        #default="{ errors }"
                                        name="courseName"
                                        rules="required"
                                    >
                                    <b-form-input
                                        id="courseName"
                                        v-model="editCourse.name"                                    
                                        type="text"  
                                        :disabled='isLoading'
                                        name='courceName'                          
                                        placeholder="Enter Text"
                                        :state="errors.length > 0 ? false:null"
                                        oncopy="return false" 
                                        onpaste="return false" 
                                        autocomplete='off'
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </span> -->
                                <span v-show="editable==false" class="output_span">{{edu.education}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4" v-if="edu.education && edu.edId">
                            <b-form-group label="Degree Name" label-for="cllgName">     
                                <span v-show="editable==false" class="output_span">{{edu.education}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4" v-if="edu.branchOrStreamOrFieldName && edu.bsfId">
                            <b-form-group label="Branch/Stream/Field Name" label-for="cllgName">     
                                <span v-show="editable==false" class="output_span">{{edu.branchOrStreamOrFieldName}}</span>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                            <b-form-group label="School/College Name">
                                <div class="output_span">{{edu.schoolOrCollegeName}}</div>
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" label="Board/University Name" label-for="branch">    
                            <!-- <span :hidden='editable==false'>
                                <validation-provider
                                        #default="{ errors }"
                                        name="field"
                                        rules="required"
                                > 
                                <v-select  
                                    v-model="editCourse.field"            
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"                               
                                    :options="editCourse.fieldArry"  
                                    :reduce="name => name.id" label="name"                           
                                    :state="errors.length > 0 ? false:null"
                                    :clearable="false"                           
                                    Onkeydown="return false"
                                    name="field"
                                    :disabled='isLoading'
                                    read
                                    id='field'                           
                                    class="per-page-selector d-inline-block mx-10 w-100"
                                    oncopy="return false" 
                                    onpaste="return false"              
                                />    
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </span>  -->
                            <span v-show="editable==false" class="output_span">
                                {{edu.boardUniversityName ? edu.boardUniversityName : ''}}
                            </span>                      
                        </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4" class="mb-1 mb-md-0">
                            <b-form-group class="mb-0" :label="edu.cgpa_percentage" label-for="percentage">     
                            <!-- <span :hidden='editable==false'>
                            <validation-provider
                                    #default="{ errors }"
                                    name="universityName"
                                    rules="required"
                            > 
                            <v-select  
                                v-model="editCourse.universityName"            
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="editCourse.universityArry"                               
                                :clearable="false"  
                                :reduce="name => name.id" label="name"     
                                :state="errors.length > 0 ? false:null"                     
                                Onkeydown="return false"
                                :disabled='isLoading'
                                name="universityName" 
                                id='universityName'
                                class="per-page-selector d-inline-block mx-7 w-100"
                                oncopy="return false" 
                                onpaste="return false"              
                            /> 
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            </span> -->
                            <span v-show='editable==false' class="output_span">{{edu.cgpa_percentageValue ? edu.cgpa_percentageValue : ''}}</span>                                
                        </b-form-group>
                        </b-col>
                        <b-col lg="3" md="4" sm="4">
                            <b-form-group class="mb-0" label="Year of Passing" label-for="passingYear">                        
                            <!-- <span :hidden='editable==false'>
                            <b-form-checkbox-group
                                v-model="editCourse.intake"
                                :options="editCourse.intakeOptions"
                                class="demo-inline-spacing"
                                id='intake'
                                :disabled='isLoading'
                                value-field="item"
                                text-field="name"
                            />   
                            </span> -->
                            <span  v-show='editable==false' class="output_span">
                                {{edu.yearOfPassing ? edu.yearOfPassing : ''}}
                            </span>             
                        </b-form-group>
                        </b-col>
                    </b-row>                                   
                </form>
            </validation-observer>
      </b-card>
      <b-row>
          <b-col md="6">
               <b-card title="German Language" class="card_body_bt0">
                <validation-observer ref="germanLangFormValidator">
                   <b-form-group label="German Language">
                    <span class="output_span" v-show="editable==false">
                        {{currentUser.germanLang}}
                    </span>
                    <span v-show="editable!==false"> 
                        <validation-provider
                            #default="{ errors }"
                            name="german language"
                            rules="required"
                        >
                        <div style="display:flex;justify-content:space-between;align-items:center;flex-wrap: wrap;height: 38px;">
                            <b-form-radio
                                v-model="currentUser.germanLang"
                                plain
                                name="german language"
                                value="None"
                            >
                                None
                            </b-form-radio>
                            <b-form-radio
                                v-model="currentUser.germanLang"
                                plain
                                name="german language"
                                value="A1"
                            >
                                A1
                            </b-form-radio>
                            <b-form-radio
                                v-model="currentUser.germanLang"
                                plain
                                name="german language"
                                value="A2"
                            >
                                A2
                            </b-form-radio>
                            <b-form-radio
                                v-model="currentUser.germanLang"
                                plain
                                name="german language"
                                value="B1"
                            >
                                B1
                            </b-form-radio>
                            <b-form-radio
                                v-model="currentUser.germanLang"
                                plain
                                name="german language"
                                value="B2"
                            >
                                B2
                            </b-form-radio>
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> 
                    </span> 
                   </b-form-group>
                </validation-observer>
              </b-card>
              <b-card title="Exams" class="card_body_bt0">               
                <b-row v-if="currentUser.overall.length!=0">
                    <b-col v-for="(score,scInd) in currentUser.overall" :key="scInd">
                        <validation-observer ref="overalFormValidator">
                            <b-form-group :label="score.title">
                                <span v-show="editable==false" class="output_span">
                                    {{score.score}}
                                </span>
                                <span v-show="editable!==false" class="output_span">                       
                                        <validation-provider
                                            #default="{ errors }"
                                            name="overall"
                                            rules="required"
                                        >
                                            <b-form-input
                                                v-model="score.score"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Enter Text" 
                                                :disabled='editable'                                  
                                                @keypress="isOnlyDecimal"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>                           
                                </span>
                            </b-form-group>
                        </validation-observer>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col md='6'>
                        <span class="output_span">
                            None
                        </span>
                    </b-col>
                </b-row>
              </b-card>             
          </b-col>
          <b-col md="6">
              <b-row>
                  <b-col md="12" v-if="false">
                        <b-card title="Student Interest" class="card_body_bt0">
                            <b-form-group label="Area of Interest">
                                {{currentUser.areaOfInterest||'--'}}
                            </b-form-group>
                        </b-card>
                  </b-col>
                  <b-col md="12">
                        <b-card
                            title="Admission offered" class="card_body_bt0"
                        >
                            <b-row md="6" class="card-title-border-bottom">
                                <b-col md="6">
                                    <b-form-group label="College" style="margin-bottom:0px !important"></b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group label="Course"  style="margin-bottom:0px !important"></b-form-group>
                                </b-col>
                            </b-row>
                            <div v-if="offeredCoursesArr.length">
                                <b-row md="6" v-for="(data,index) in offeredCoursesArr" :key="index">
                                    <b-col md="6">
                                        <label style="font-size: 16px;" :style="data.isBold ? 'font-weight: 600;' : ''">{{data.college_name}}</label>
                                    </b-col>
                                    <b-col md="6">
                                        <label style="font-size: 16px;" :style="data.isBold ? 'font-weight: 600;' : ''">{{data.dispCourseName}}</label>
                                    </b-col>
                                </b-row>
                            </div>
                            <div v-else>
                                <b-row md="6">
                                    <b-col md="6">
                                        <label style="font-size: 16px;">--</label>
                                    </b-col>
                                    <b-col md="6">
                                        <label style="font-size: 16px;">--</label>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-card>
                    </b-col>
                  <b-col md="12" v-if="false">
                    <b-card title="Field of Interest" class="card_body_bt0">
                        <span v-if='currentUser.fieldofIntrest!==null'>
                        <span v-show="editable==false" v-for="(intData,ind) in currentUser.fieldofIntrest" :key="ind" class="main_list">
                        <b-form-group v-if='intData.course !== undefined || intData.course !== null' :label="intData.course.name">
                            <span v-show="editable==false">
                                <p class="output_span" v-if='intData.course !== undefined || intData.course !== null'>{{intData.field.name}}</p>
                            </span>
                        </b-form-group>
                        </span> 
                        </span>
                            <span v-show="editable!==false" class="output_span"> 
                            <!-- form -->
                            <validation-observer ref="intrestingFieldsForm">
                            <b-form>
                                <b-row v-for="(data,index) in currentUser.fieldofIntrest" :key="index" :style="currentUser.fieldofIntrest.length>0?'border-bottom:1px solid #cfcfcf':''" style="margin-top:10px">
                                    <!-- Select Field -->
                                    <b-col md="6">
                                        <b-form-group label="Select Field">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select field"
                                            rules="required"
                                            :vid="'select field'+index"
                                        >
                                            <b-form-select
                                                v-model="data.field"
                                                :ref='data.field'                                        
                                                :options="fieldOptions"
                                                :disabled='isLoading'
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                                @change="filterCourse(index)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>

                                    <!-- Select Course -->
                                    <b-col md="6">
                                        <b-form-group label="Select Course">
                                        <validation-provider
                                            #default="{ errors }"
                                            name="select course"
                                            rules="required"
                                            :vid="'select course'+index"
                                        >
                                            <b-form-select
                                                v-model="data.course"
                                                :ref='data.course'                                        
                                                :disabled="data.field.id == ''"
                                                :options="data.courseOptions"
                                                :state="errors.length > 0 ? false:null"
                                                placeholder="Select"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <div class="add_edu_link" style="font-weight:600;margin-bottom:10px;width:100%;display:flex;justify-content:space-between;padding:0px 14px">
                                        <a v-if="index==currentUser.fieldofIntrest.length-1" style="text-decoration:underline;color:#3b3bf6;" @click.prevent="addNewRow()">
                                            Add New Row
                                        </a>
                                        <a v-if="index!=0" class="add_edu_link_remove" style="text-decoration:underline;color:#ff0b0b" :style="index!=currentUser.fieldofIntrest.length-1?'text-align: right;display: block;width: 100%;':''" @click.prevent="removeRow(index)">
                                            Remove
                                        </a>
                                    </div>
                                </b-row>
                                <b-col cols="12">
                                </b-col>
                            </b-form>
                            </validation-observer>
                            </span>
                    </b-card>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
       <!-- <div v-if="isSpiner" class="loading loading-custom">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>     
       </div> -->
    </div>
    <b-spinner variant="primary" v-if="isSpiner" large class="pageLoadSpinners"/>
</div>
</template>
<script>
import {  extend ,ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules'
import {
  BCard, BRow, BCol, BFormGroup, BFormFile, BFormInput, BFormDatepicker, BFormRadio, BFormSelect, BForm, BBadge, BSpinner
} from 'bootstrap-vue'
import moment from 'moment'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import { dbCollections } from '@/utils/firebaseCollection.js';
import { ValidationFunction } from '@/utils/globalValidations.js';
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from '@/utils/firebaseInit';
import firebaseTimeStamp from 'firebase'
const db = firebase.firestore();
import formatPhoneNumber from '@/utils/phoneNumberFormat.js';
// import { concat } from 'postcss-rtl/lib/affected-props';
extend('required',required)
export default{
   components:{
       BCard,
       BRow,
       BCol,
       BFormGroup,
       ValidationProvider,
       BFormFile,
       ValidationObserver,
       BFormInput,
       BFormDatepicker,
       BFormRadio,
       BFormSelect,
       BForm,
       BBadge,
       BSpinner 
   }, 
   data(){
       return{
            offeredCoursesArr:[],
           docCategory:[],
           currentUserId:'',
           editable:false,
           isLoading:false,
           currentUser:{
               userId:'',
               name:'',
               email:'',
               mobile:'',
               dob:'',
               birthCity:'',
               cllgName:'',
               gender:'',
               branch:'',
               percentage:'',
               passingYear:'',
               overall:'',
               add1:'',
               add2:'',
               representative:'',
               germanLang:'',
               fieldofIntrest:[{
                field:{
                name:'',id:''
                },
                course:{
                    name:'',id:'',fieldId:''
                },
                courseOptions:[{value:null,text:'Select'}]
                }],
               profilePic:'',
               profilePicName:'',
               documentModule:[],
               docModuleName:'',
               userStatus:{
                   name:'',
                   variant:''
               },
               areaOfInterest:'',
           },
           fieldOfInterests:[{
                field:null,
                course:null
           }],
           courseOptions:[
                { value: null, text: 'Select' },
           ],
           allCourse:[],    
           fieldOptions:[
                { value: null, text: 'Select' },
           ], 
           userProfile:[],
           profileImg:[],
           isSpiner:false      
       }
   },
//    watch: {
//     $route: function () {        
//         this.getData();          
//     }
//    },
    created(){
        let currentUser = JSON.parse(localStorage.getItem('currentUser'))
        if ('Notification' in window) {
            if (Notification.permission === 'denied' || Notification.permission == 'default') {
                this.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
            } else {
                Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                    console.info('Notification permission has been granted.');
                    } else {
                    console.info('Notification permission has been denied.');
                    }
                })
                .catch(error => {
                    console.error('Error occurred while requesting notification permission:', error);
                });
            }
        } else {
            this.$root.$emit('showToastMessage','To receive notifications, please allow Notification permission in site settings and reload page again.','warning')
        }
        if(this.editable==false){
            this.$root.$emit('studentProfileEditableOff',(this.editable))  
        }
        this.getData();
        //    BIND EDITABLE USER DATA
        this.$root.$on('currentStudentProfileEdit',()=>{      
            //    this.editable=true;  
            let currUser= JSON.parse(localStorage.getItem('userData'))                  
            this.$root.$emit('studentProfileEditableOff',(this.editable)) 
            this.$root.$emit('stopSpinner')
            this.$router.replace({name:'studentapplicationEditDetails',params:{name:this.currentUser?.name ? this.currentUser.name : currUser.fullName,id:currentUser.id}})
        })
        //    CANCEL USER DATA
            this.$root.$on('canceleditStudentProfile',()=>{
            this.editable=false;        
            this.resetAllFields();
            this.getData();
            this.$root.$emit('studentProfileEditableOff',(this.editable))  
        }) 
        //    SAVE UPDATE  
        this.$root.$on('currentStudentProfileUpdate',()=>{
            //    check profile data      
            this.$refs.FormValidator.validate().then(formvalidatorsuccess => {
                    if (formvalidatorsuccess) {
                        // let fieldIntrestArry = [];
                        // if(this.currentUser.fieldofIntrest.length > 0){
                        //     this.currentUser.fieldofIntrest.forEach(ele=>{
                        //         fieldIntrestArry.push({
                        //             'course':ele.course,
                        //             'field':ele.field
                        //         })
                        //     })                                               
                        // }
                            //    check scores
                            // this.$refs.overalFormValidator.validate().then(overalFormsuccess => {
                            //     if (overalFormsuccess) {
                                    // check germoney language
                                    // this.$refs.germanLangFormValidator.validate().then(langFormsuccess => {
                                    //     if (langFormsuccess) {
                                            // console.log(this.$refs.intrestingFieldsForm.validate(),"this.$refs.intrestingFieldsForm.validate()")
                                            // this.$refs.intrestingFieldsForm.validate().then(success => {
                                            //     if (success) {
                                                if(this.userProfile.length == 0){ 
                                                    let saveObj = {
                                                        'name': this.currentUser.name,
                                                        'mobileNumber': this.currentUser.mobile,
                                                        'dob': this.currentUser.editDob, 
                                                        'gender': this.currentUser.gender,
                                                        'addressLine1': this.currentUser.add1,
                                                        'addressLine2':this.currentUser.add2,                                                    
                                                        'germanLanguage': this.currentUser.germanLang,
                                                        'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()
                                                        // 'fieldOfInterests' : fieldIntrestArry
                                                    }                                               
                                                    updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.currentUserId.id,saveObj,res=>{
                                                    this.$toast({
                                                        component: ToastificationContent,
                                                        position: 'top-right',
                                                        props: {
                                                            title: 'Document Update Successfully',
                                                            icon: 'CoffeeIcon',
                                                            variant: 'success',                               
                                                        },
                                                    })
                                                    this.getData();
                                                    this.resetAllFields();
                                                    this.editable=false;
                                                    this.$root.$emit('studentProfileEditableOff',(this.editable))
                                                    this.$root.$emit('stopSpinner')
                                                    }) 
                                                }else{
                                                    let count = 0;
                                                    let fileArry = this.profileImg;                                              
                                                    const uploadFileFunction = (row) => {
                                                    if(count >= fileArry.length){                                                   
                                                        return;                
                                                    }                                               
                                                        this.uploaduserProfileFile(row,(saveRes)=>{                                                        
                                                            count += 1;
                                                            if(count >= fileArry.length){
                                                                let saveObj = {
                                                                    'name': this.currentUser.name,
                                                                    'mobileNumber': this.currentUser.mobile,
                                                                    'dob': this.currentUser.editDob, 
                                                                    'gender': this.currentUser.gender,
                                                                    'addressLine1': this.currentUser.add1,
                                                                    'addressLine2':this.currentUser.add2,                                                    
                                                                    'germanLanguage': this.currentUser.germanLang,
                                                                    // 'fieldOfInterests' : fieldIntrestArry,
                                                                    'imageUrl': saveRes.imageUrl,
                                                                    'imageName':saveRes.imageName,
                                                                    'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()              
                                                                }
                                                                updateQuery.updateRootCollectionDataByDocId(dbCollections.USERS,this.currentUserId.id,saveObj,res=>{
                                                                this.$toast({
                                                                    component: ToastificationContent,
                                                                    position: 'top-right',
                                                                    props: {
                                                                        title: 'Document Update Successfully',
                                                                        icon: 'CoffeeIcon',
                                                                        variant: 'success',                               
                                                                    },
                                                                })
                                                                this.getData();
                                                                this.resetAllFields();
                                                                this.editable=false;
                                                                this.$root.$emit('studentProfileEditableOff',(this.editable))
                                                                this.$root.$emit('stopSpinner')   
                                                                }) 
                                                            }else{
                                                            uploadFileFunction(fileArry[count]);
                                                            }
                                                        });              
                                                    }                                               
                                                    uploadFileFunction(fileArry[count]); 
                                                }

                                            //     }
                                            // }) 
                                    //     }else{
                                    //         this.$root.$emit('stopSpinner')
                                    //     } 
                                    // })
                            //     }else{
                            //                 this.$root.$emit('stopSpinner')
                            //     } 
                            //  })  
                    } 
                    else{
                        this.$root.$emit('stopSpinner')
                    } 
            })         
        })            

        this.offeredApplications();
    },
    methods:{
        formatPhoneNumber,
        addZero(val)
        {
            if(val<10)
            {
                return `0`+val
            }
            else
            {
                return val
            }
        },
        getMaxDate()
        {
            return `${this.addZero(new Date().getFullYear())}-${this.addZero(new Date().getMonth()+1)}-${this.addZero(new Date().getDate())}`
        },
        init(){
          var self = this;     
           self.currentUserId = JSON.parse(localStorage.getItem('userData'));
           
            getQuery.getDataWithSpecificWhereCondition(dbCollections.DOCUMENTCATEGORY,'isDeleted','==',false,res=>{
                self.docCategory = res;
            })
            // setTimeout(()=>{
            //    GET ALL COURCES 
            getQuery.getDataWithSpecificWhereCondition(dbCollections.COURSES,'isDeleted','==',false,res=>{
                self.allCourse = [];
                if(res){
                    res.forEach(val=>{
                        self.allCourse.push({
                                text:val.courseName,
                                value:{
                                name:val.courseName,
                                id:val.id,
                                fieldId:val?.fieldData?.id ? val.fieldData.id : (val?.fieldName?.id || '')
                            }
                        })
                        // doc.data().courses.forEach(val2=>{
                        //     self.allCourse[self.allCourse.length-1].course.push(val2.name)
                        // })
                    })               
                } 
            })
            //    GET ALL FIELDS 
            getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDS,'isDeleted','==',false,res=>{             
                    self.fieldOptions=[
                            { value: null, text: 'Select' },
                    ];
                if(res){
                    res.forEach(val=>{
                        if(val){
                            self.fieldOptions.push({
                                text:val.fieldName,
                                value:{
                                    name:val.fieldName,
                                    id:val.id
                                    }
                            })
                        }
                    }) 
                }
            })           

        // },1000)
       },
       getData(){
           var self = this;
           self.isSpiner = true;
           self.init();
        setTimeout(()=>{
            getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,self.currentUserId.id,async(res)=>{    
                let representativeNewArray = [];
                if (res.representative && Object.keys(res.representative).length) {
                    const visitedIds = new Set();

                    function getDataWithPromise(collection, docId) {
                        return new Promise((resolve, reject) => {
                            if (!visitedIds.has(docId)) {
                                visitedIds.add(docId);
                                getQuery.getDataFromRootCollectionWithDocID(collection, docId, resolve);
                            } else {
                                resolve();
                            }
                        });
                    }

                    async function processRepresentative(repData) {
                        const resEmp = await getDataWithPromise(dbCollections.USERS, repData.id);
                        if (resEmp?.representative && Object.keys(resEmp.representative).length) {
                            if (resEmp.personalPhoneNo && (resEmp.isPhoneNumberShow === undefined || (resEmp.isPhoneNumberShow !== undefined && resEmp.isPhoneNumberShow === true))) {
                                representativeNewArray.push({phoneNo:(resEmp.countryPhoneCode ? resEmp.countryPhoneCode.dialCode : '+91') + ' ' +(( (resEmp.countryPhoneCode ? formatPhoneNumber(resEmp.personalPhoneNo,resEmp.countryPhoneCode.mask) : formatPhoneNumber(resEmp.personalPhoneNo,'99999 99999')))),name:resEmp.firstName + ' ' + resEmp.lastName});
                            }
                            await processRepresentative(resEmp.representative);
                        }
                    }

                    await processRepresentative(res.representative);
                }
            //    self.currentUser.fieldofIntrest = []; 
               self.currentUser.documentModule = [];  
               self.currentUser.docModuleName = "";    
               if(res.isDeleted == false && Object.keys(res).length > 0){   
                    if(this.$route.name !== 'studentapplicationEditDetails') {
                        self.$root.$emit('changeLoggedUserName',res.name)                               
                    }
                   self.currentUser.userId = res.uniqueId;
                   self.currentUser.name = res.name;
                   self.currentUser.email = res.email;
                   self.currentUser.mobile = res.mobileNumber;
                   self.currentUser.editDob = res.dob;
                   self.currentUser.dob = moment(res.dob,'YYYY-MM-DD').format('DD MMM, YYYY');        
                   self.currentUser.birthCity = '';
                   self.currentUser.education = res.educationDetails;
                   self.currentUser.cllgName=res.collegeName;  
                   self.currentUser.gender = res.gender;         
                   self.currentUser.intake = res.intake;         
                   self.currentUser.year = res.year;         
                   self.currentUser.branch=res.branch;
                   self.currentUser.percentage=res.cgpa_percentage;
                   self.currentUser.passingYear=res.yearOfPassing;
                   self.currentUser.overall=res.exams;
                   self.currentUser.add1=res.addressLine1;
                   self.currentUser.add2=res.addressLine2;
                   self.currentUser.representative=res.representative||'--';
                   self.currentUser.representativeRelationArray=representativeNewArray||'--';
                   self.currentUser.germanLang = res.germanLanguage;                   
                //    self.currentUser.fieldofIntrest = res.fieldOfInterests;
                   self.currentUser.profilePic=res.imageUrl
                   self.currentUser.profilePicName=res.imageName
                   self.currentUser.documentModule=res.documentModule;
                   self.currentUser.userStatus.name=res.status.status;
                   self.currentUser.userStatus.variant=res.status.variant;                    

                   self.currentUser.areaOfInterest=res.areaOfInterest
                   self.currentUser.countryPhoneCode=res.countryPhoneCode ? res.countryPhoneCode : { "ru": "Индия", "lt": "Indija", "tr": "Hindistan", "en": "India", "flag": "🇮🇳", "code": "IN", "dialCode": "+91", "mask": "99999 99999"}
                    //    GET MODULE NAME                 
                    if(self.currentUser.documentModule.length>0){                      
                        self.currentUser.documentModule.forEach((docMod)=>{
                            if(docMod.id){     
                                if(self.docCategory.length > 0){                          
                                let findcatInd = self.docCategory.findIndex((catId)=>{
                                    return catId.id == docMod.id;
                                })
                                if(findcatInd > 0){
                                    if(self.currentUser.docModuleName == ''){
                                        self.currentUser.docModuleName = self.docCategory[findcatInd].categoryName;
                                    }else{                                       
                                        self.currentUser.docModuleName = self.currentUser.docModuleName+','+self.docCategory[findcatInd].categoryName;
                                    }
                                }
                            }                              
                            }
                        })
                    }
                   //    SET COURCE AND FIELD ARRAY
                //    if(self.currentUser.fieldofIntrest.length > 0){
                //        self.fieldOfInterest = [];
                //        self.currentUser.fieldofIntrest.forEach((val,index)=>{
                //            self.fieldOfInterests.push({
                //                field:{name:val.field.name,id:val.field.id},
                //                course:{name:val.course.name,id:val.course.id,fieldId:val.field.id}
                //            })
                //            self.filterCourse(index);
                //        })                         
                //    }
               }
               self.isSpiner = false;
           })          
        },4000)            
      },
       isOnlyDecimal(event) {
            ValidationFunction.OnlyFloatWithNumber(event);
       },
       filterCourse(index)
        {
            var self = this
            self.currentUser.fieldofIntrest[index].courseOptions=[{value:null,text:'Select'}]
            self.allCourse.forEach(data=>{              
                if(data.value.fieldId==self.currentUser.fieldofIntrest[index].field.id)
                {
                    self.currentUser.fieldofIntrest[index].courseOptions.push(data)                
                }
            })
       },
       addNewRow(){           
            //add field
            this.currentUser.fieldofIntrest.push({
            field:{
                name:'',id:''
            },
            course:{
                name:'',id:'',fieldId:''
            },
            courseOptions:[{value:null,text:'Select'}]
            })
       },
       removeRow(index)
        {
            //remove field
            this.currentUser.fieldofIntrest.splice(index, 1)
       },
       uploaduserProfileFile(data,cb){           
            uploadImageFile.removeImage('/Student Images',this.currentUser.profilePicName,callBack=>{
               uploadImageFile.uploadImageFile('Student Images',data.name,data,res=>{
                   this.$root.$emit('changeProfile',res.imageUrl)
                   cb(res) 
               })               
           })           
       },
       resetAllFields(){
           var self = this;
           self.userProfile = [];
       },
       //Check if is valid(image) file
        checkFile(e)
        {
            var files = e.target.files || e.dataTransfer.files;         
            this.profileImg = files
        },
        offeredApplications(){
            var self = this;

            let res2 = [];

            db
                .collection(dbCollections.APPLICATIONS)
                .orderBy('createdAt','desc')
                .where('selectedStudent.id','==',self.currentUserId.id)
                .get()
                .then((querySnapshot)=>{
                    if(querySnapshot.empty) {
                        this.offeredCoursesArr = res2;
                    }

                    var res = []
                    querySnapshot.forEach(doc=>{
                        res.push(doc.data())
                    })
                    let applicationData = [];
                    if(res){
                        res.forEach(elements=>{
                            if(elements.selectedStudent.id == self.currentUserId.id){
                                elements.ApplyDate = moment(elements.createdAt.toDate()).format('DD MMM, YYYY')                                                      
                                elements.shortlistPeding = 0;
                                elements.shortlist_pay_pend = 0;
                                elements.shortPaySuccessful = 0;
                                elements.shortApplicationSub = 0;
                                elements.shortlistinProgress = 0;
                                elements.shortlistSuccessfull = 0;
                                elements.shortlistreject = 0;
                                elements.displayStatus = '';
                                if(elements.application_Status.toLowerCase()=='pending'){
                                    elements.displayStatus = 'Pending'
                                    elements.applicationStatusVariant = 'primary'
                                }
                                if(elements.application_Status.toLowerCase()=='inprogress'){
                                    elements.displayStatus = 'In Progress'
                                    elements.applicationStatusVariant = 'warning'
                                }
                                if(elements.application_Status.toLowerCase()=='paymentpending'){
                                    elements.displayStatus = 'Payment Pending'
                                    elements.applicationStatusVariant = 'secondary'
                                }
                                if(elements.application_Status.toLowerCase()=='paymentsuccessful'){
                                    elements.displayStatus = 'Payment Successful'
                                    elements.applicationStatusVariant = 'info'
                                }
                                if(elements.application_Status.toLowerCase()=='applicationsubmitted'){
                                    elements.displayStatus = 'Application Submitted'
                                    elements.applicationStatusVariant = 'dark'
                                }
                                if(elements.application_Status.toLowerCase()=='admissionsuccessful'){
                                    elements.displayStatus = 'Admission Offered'
                                    elements.applicationStatusVariant = 'success'
                                }
                                if(elements.application_Status.toLowerCase()=='admissionrejected'){
                                    elements.displayStatus = 'Rejection'
                                    elements.applicationStatusVariant = 'danger'
                                }                            
                                // , 'successBackGround': (data.item.application_Status.toLowerCase()=='admissionsuccessful'), 'progressBackGround':(data.item.application_Status.toLowerCase()=='inprogress'), 'rejectedBackGround':(data.item.application_Status.toLowerCase()=='admissionrejected'))
                                //COUNT SHORTLIST STATUS
                                if(elements.shortListedColleges !== undefined){
                                    
                                    elements.shortListedColleges.forEach(valuess=>{
                                        if(valuess){ 
                                            if(valuess.status == 'pending'){
                                            elements.shortlistPeding = Number(JSON.parse(elements.shortlistPeding) + 1);
                                            }
                                            if(valuess.status == 'inProgress'){
                                            elements.shortlistinProgress = Number(JSON.parse(elements.shortlistinProgress) + 1);
                                            }
                                            if(valuess.status == 'admissionSuccessful'){
                                            elements.shortlistSuccessfull = Number(JSON.parse(elements.shortlistSuccessfull) + 1);
                                            }
                                            if(valuess.status == 'admissionRejected'){
                                            elements.shortlistreject = Number(JSON.parse(elements.shortlistreject) + 1);
                                            } 
                                            if(valuess.status == 'paymentPending'){
                                                elements.shortlist_pay_pend = Number(JSON.parse(elements.shortlist_pay_pend) + 1);
                                            }
                                            if(valuess.status == 'paymentSuccessful'){
                                                elements.shortPaySuccessful = Number(JSON.parse(elements.shortPaySuccessful) + 1);
                                            }
                                            if(valuess.status == 'applicationSubmitted'){
                                                elements.shortApplicationSub = Number(JSON.parse(elements.shortApplicationSub) + 1);
                                            }
                                        }
                                    })
                                }
                                applicationData.push(elements) 
                            }
                        })
                        // console.log(applicationData,"this.applicationData")
                    }

                    applicationData.forEach((data=>{
                        if(data.shortListedColleges && data.shortListedColleges.length) {
                            data.shortListedColleges.filter((x) => {
                                if (x.status == "admissionSuccessful") {
                                    let ind = res2.findIndex((y)=> y.college_name === x.college_name && y.dispCourseName === x.dispCourseName )
    
                                    if(ind === -1) {
                                        res2.push({...x,isBold:data.finalSelected ? (data.finalSelected.cllgName === x.college_name && data.finalSelected.courceName === x.dispCourseName) : false});
                                    }
                                    // res2.push({...x,isBold:data.finalSelected ? (data.finalSelected.cllgName === x.college_name && data.finalSelected.courceName === x.dispCourseName) : false});
                                    return true;
                                }
                            });
                        }
                    }))
                    this.offeredCoursesArr = res2;
                })
                .catch(error=>{
                    console.log("error",error)
                    this.offeredCoursesArr = res2;
                })
        }
    },
}
</script>
<style scoped>
.addEdit{
    border: 1px black;
    display: inline-block;
    border-style: dashed;
    margin: 3px
}
.per-page-selector {
  width: 170px;
}
.propsObj{
  visibility: hidden;
}
.text-overflow {
  height: 20px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 5px 0 5px;
  text-align: left;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
}
.btn-overflow {
  text-decoration: none;
  overflow: hidden;
}
.btn-overflowHidden{
  display: none; 
  text-decoration: none
}
@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>